// 绘画
const drawing = {
	first: {
		url: "/addons/chatgpt/draw/prompts",
		method: "GET",
		desc: "绘图分类"
	},
	config: {
		url: "/addons/chatgpt/draw/prompt_config",
		method: "GET",
		desc: "配置表"
	},
	create: {
		url: "/addons/chatgpt/draw/text2img",
		method: "POST",
		desc: "生成图片"
	},
	result: {
		url: "/addons/chatgpt/draw/draw_result",
		method: "GET",
		timeout: 1000 * 100,
		desc: "绘图结果"
	},
	collect: {
		url: "/addons/chatgpt/draw/draw_image_vote",
		method: "GET",
		desc: "点赞或收藏"
	},
	collectList: {
		url: "/addons/chatgpt/draw/image_vote_list",
		method: "GET",
		desc: "点赞或收藏列表"
	},
	record: {
		url: "/addons/chatgpt/draw/user_draw",
		method: "GET",
		desc: "绘图记录"
	},
	atlas: {
		url: "/addons/chatgpt/draw/draw_images",
		method: "GET",
		desc: "展览图集"
	},
	keyword: {
		url: "/addons/chatgpt/draw/key_words",
		method: "GET",
		desc: "描述关键词"
	},
	alltag: {
		url: "/addons/chatgpt/draw/alltag",
		method: "GET",
		desc: "获取tag"
	},
	onetag: {
		url: "/addons/chatgpt/draw/onetag",
		method: "GET",
		desc: "获取指定tag"
	},

	template: {
		url: "/addons/chatgpt/draw/prompt_words",
		method: "GET",
		desc: "描述词模板"
	},
	matter: {
		url: "/addons/chatgpt/draw/attachment",
		method: "GET",
		desc: "上传的本地素材库"
	},
	picture: {
		url: "/addons/chatgpt/draw/img2img",
		method: "POST",
		desc: "以图生图"
	},
	translate: {
		url: "/addons/chatgpt/draw/entranslate",
		method: "POST",
		desc: "翻译"
	},
	picture2text: {
		url: "/addons/chatgpt/draw/img2text",
		method: "POST",
		desc: "以图生文"
	},
	pictureHD: {
		url: "/addons/chatgpt/draw/imgHD",
		method: "POST",
		desc: "图像高清修复"
	},
	pictureface: {
		url: "/addons/chatgpt/draw/imgface",
		method: "POST",
		desc: "AI换脸"
	},
	pictureMerge:{
		url: "/addons/chatgpt/draw/imgMerge",
		method: "POST",
		desc: "多图融合"
	},

	pictureArtqr: {
		url: "/addons/chatgpt/draw/imgArtqr",
		method: "POST",
		desc: "艺术二维码"
	},
	drawConfig: {
		url: "/addons/chatgpt/draw/draw_config",
		method: "POST",
		desc: "以图生文"
	},
	delete: {
		url: "/addons/chatgpt/draw/user_draw_del",
		method: "GET",
		desc: "删除生成的图片"
	}
}

export default drawing
