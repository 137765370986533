<template>
	<div>
		<!-- Settings Drawer -->
		<a-drawer class="settings-drawer" :class="[rtl ? 'settings-drawer-rtl' : '']" :placement="rtl ? 'left' : 'right'" :closable="false" :visible="showSettingsDrawer" width="360" :getContainer="() => wrapper" @close="$emit('toggleSettingsDrawer', false)">
			<!-- Settings Drawer Close Button -->
			<a-button type="link" class="btn-close" @click="$emit('toggleSettingsDrawer', false)">
				<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
					<g id="close" transform="translate(0.75 0.75)">
						<path id="Path" d="M7.5,0,0,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" />
						<path id="Path-2" data-name="Path" d="M0,0,7.5,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" />
					</g>
				</svg>
			</a-button>
			<!-- / Settings Drawer Close Button -->

			<!-- Settings Drawer Content -->
			<div class="drawer-content">
				<h6>设置</h6>
				
				<hr />
			
				<span  style="font-size: 14px;color: red;margin-right: 50px;">温馨提示：Ctrl+D 收藏网站，下次再用</span>
				<div class="download">
					<a-button type="secondary" block > <a href="/#/profile" target="_blank">个人中心</a></a-button>
					<a-button type="secondary" block @click="rightButtonHandle('wxoa')">关注公众号</a-button>
					<a-button type="secondary" block @click="rightButtonHandle('wxscan')">微信扫码使用</a-button><!-- 
					<a-button type="secondary" block ><a href="https://www.osaigc.cn/" target="_blank">关于我们</a></a-button>
					<a-button type="primary" block > <a href="https://www.osaigc.com/downloads/" target="_blank"><a-icon type="windows" theme="filled" />windows下载</a></a-button>
					<a-button type="primary" block > <a href="https://www.ccopyright.net.cn/downloads/" target="_blank"><a-icon type="tablet" theme="twoTone" />APP下载</a></a-button> -->
					<a-button v-if="token" type="danger" ghost block @click="logoutHandle">退出登录</a-button>
				</div>
				
			
			</div>
			<!-- / Settings Drawer Content -->
		</a-drawer>
		<!-- / Settings Drawer -->

		<a-modal v-if="showModelType === 'wxoa' && config.wxOfficialAccount && config.wxOfficialAccount.qrcode" v-model="showModal" :width="400" :footer="null" :centered="true" :closable="false" :maskClosable="true" :bodyStyle="{ padding: 0 }">
			<div class="show_modal">
				<div class="wxoa">
					<img :src="config.wxOfficialAccount.qrcode" alt="" />
				</div>
			</div>
		</a-modal>
		<a-modal v-if="showModelType === 'wxscan'" v-model="showModal" :width="400" :footer="null" :centered="true" :closable="false" :maskClosable="true" :bodyStyle="{ padding: 0 }">
			<div class="show_modal">
				<div class="wxscan">
					<div ref="qrCodeUrl"></div>
				</div>
			</div>
		</a-modal>
		<a-modal v-if="showModelType === 'contact' && config.contact_us && config.contact_us.content" v-model="showModal" :width="400" :footer="null" :centered="true" :closable="false" :maskClosable="true" :bodyStyle="{ padding: 0 }">
			<div class="show_modal">
				<div class="contact" v-html="config.contact_us.content"></div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import "vue-github-buttons/dist/vue-github-buttons.css" // Stylesheet
	import VueGitHubButtons from "vue-github-buttons"
	import Vue from "vue"
	Vue.use(VueGitHubButtons, { useCache: true })
	import QRCode from "qrcodejs2"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		props: {
			// Settings drawer visiblility status.
			showSettingsDrawer: {
				type: Boolean,
				default: false
			},
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary"
			},
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light"
			},
			// Header fixed status.
			navbarFixed: {
				type: Boolean,
				default: false
			},
			// Drawer direction.
			rtl: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				// The wrapper element to attach dropdowns to.
				wrapper: document.body,
				// Main sidebar color.
				sidebarColorModel: this.sidebarColor,
				// Main sidebar theme : light, white, dark.
				sidebarThemeModel: this.sidebarTheme,
				// Header fixed status.
				navbarFixedModel: this.navbarFixed,
				showModal: false,
				showModelType: "wxoa" // wxoa wxscan contact
			}
		},
		computed: {
			...mapState("app", ["config", "sign"]),
			...mapGetters("user", ["token", "userInfo"])
		},
		mounted: function () {
			// Set the wrapper to the proper element, layout wrapper.
			this.wrapper = document.getElementById("layout-dashboard")
		},
		methods: {
			// 生成二维码
			creatQrCode() {
				// 重复创建二维码会，之前的二维码还在，新创建的会被接在下面
				// 此行代码是为了解决以上问题 或者 刷新二维码的时候生成多个二维码的问题
				if (this.$refs.qrCodeUrl) this.$refs.qrCodeUrl.innerHTML = ""
				new QRCode(this.$refs.qrCodeUrl, {
					text: this.config.h5, // 需要转换为二维码的内容
					width: 340,
					height: 340,
					correctLevel: QRCode.CorrectLevel.H
				})
			},
			// 按钮
			rightButtonHandle(type) {
				this.showModelType = type
				this.showModal = true
				this.$nextTick(() => {
					type == "wxscan" && this.creatQrCode()
				})
			},
			// 退出登录
			logoutHandle() {
				this.$confirm({
					title: "确认要退出登录吗？",
					content: "",
					okText: "确认",
					cancelText: "取消",
					onOk: () => {
						return new Promise((resolve, reject) => {
							this.$store.dispatch("user/logout")
							this.$emit("toggleSettingsDrawer", false)
							resolve()
						}).catch(() => {})
					}
				})
			},
			// 复制
			async copyShareLink() {
				const spm = this.userInfo.id + ".1.0.4.3"
				const link = window.location.origin + window.location.pathname + `?${this.sign}#/?share=${spm}`
				try {
					await navigator.clipboard.writeText(link)
					this.$message.success("已复制到剪切板")
				} catch (err) {
					this.$message.error("复制失败")
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .ant-modal-content {
		border-radius: 10px;
		overflow: hidden;
	}
	.show_modal {
		.wxoa {
			img {
				width: 400px;
				height: 400px;
			}
		}

		.wxscan {
			width: 400px;
			height: 400px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.contact {
			::v-deep img {
				width: 100%;
				height: auto;
			}
		}
	}
</style>
