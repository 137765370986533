/**
 * 时间戳转换日期时间
 * @param { String } timestamp 时间戳
 * @return { String }
 */
const timestampToTime = timestamp => {
	let date = new Date(timestamp * 1000), // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
		Y = date.getFullYear() + "-",
		M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-",
		D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
		h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":",
		m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()

	// m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':',
	// s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())

	return Y + M + D + h + m
}

/**
 * 获取url中参数 (hash后拼接的参数)
 * @returns
 */
const getUrlParams = () => {
	const url = window.location.hash
	const querys = url.substring(url.indexOf("?") + 1).split("&")
	const result = {}
	for (let i = 0; i < querys.length; i++) {
		const temp = querys[i].split("=")
		if (temp.length < 2) {
			result[temp[0]] = ""
		} else {
			result[temp[0]] = temp[1]
		}
	}
	return result
}

/**
 * 防抖
 * @param { Function } callback 回调
 * @param { Number } time 延时
 * @param { Boolean } immediate 是否立即执行
 * @returns
 */
const Debounce = (callback, time = 500, immediate = true) => {
	let timer = null
	return function () {
		if (timer) clearTimeout(timer)
		if (immediate) {
			let callNow = !timer
			timer = setTimeout(() => {
				timer = null
			}, time)
			if (callNow) callback.apply(this, arguments)
		} else {
			timer = setTimeout(() => {
				callback.apply(this, arguments)
			}, time)
		}
	}
}

/**
 * 下载图片
 * @param { String } imgSrc 图片路径
 * @param { String } name 图片名称
 */
const downloadPicture = (imgSrc, name) => {
	const time = new Date().getTime()
	const image = new Image()
	// 解决跨域 Canvas 污染问题
	image.src = imgSrc
	image.setAttribute("crossOrigin", "anonymous")
	image.onload = () => {
		const canvas = document.createElement("canvas")
		canvas.width = image.width
		canvas.height = image.height
		const context = canvas.getContext("2d")
		context.drawImage(image, 0, 0, image.width, image.height)
		const url = canvas.toDataURL("image/jpg")
		const a = document.createElement("a")
		a.download = name || `image_${time}`
		a.href = url
		a.click()
		a.remove()
	}
	
}

/**
 * 图片尺寸
 * @param { Boolean } long 长边 = s / 短边 = s
 * @param { Number } w 原图片宽度
 * @param { Number } h 原图片高度
 * @param { Number } s 短边的尺寸
 * @returns
 */
const sizePicture = (long = false, w, h, s = 450) => {
	const img = {
		w: 0,
		h: 0
	}
	if (!w || !h) {
		Promise.reject("图片尺寸异常 => 已拦截")
		return img
	}
	if (long) {
		img.w = w == h ? s : w > h ? s : (s / h) * w
		img.h = w == h ? s : w > h ? (s / w) * h : s
	} else {
		img.w = w == h ? s : w > h ? (s / h) * w : s
		img.h = w == h ? s : w > h ? s : (s / w) * h
	}

	return img
}

/**
 * 数组重新分组
 * @param { Array } data
 * @param { Number } chunk
 * @returns
 */
const regroup = (data, chunk = 5) => {
	let result = []
	for (let index = 0; index < data.length; index++) {
		if (result[index % chunk]) {
			result[index % chunk].push(data[index])
		} else {
			result[index % chunk] = [data[index]]
		}
	}
	return result
}

export { timestampToTime, getUrlParams, Debounce, downloadPicture, sizePicture, regroup }
