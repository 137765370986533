// 角色
const role = {
	first: {
		url: "/addons/chatgpt/rule/topic",
		method: "GET",
		desc: "一级分类"
	},
	second: {
		url: "addons/chatgpt/rule/prompts",
		method: "GET",
		desc: "二级分类"
	},
	detail: {
		url: "/addons/chatgpt/rule/prompt",
		method: "GET",
		desc: "分类详情"
	},
	collectList: {
		url: "/addons/chatgpt/rule/vote_prompts",
		method: "GET",
		desc: "收藏的二级分类列表"
	},
	collect: {
		url: "/addons/chatgpt/rule/vote_prompt",
		method: "GET",
		desc: "收藏/取消收藏二级分类"
	},
	create: {
		url: "/addons/chatgpt/web/rule_group",
		method: "GET",
		desc: "创建角色会话"
	},
	history: {
		url: "/addons/chatgpt/web/history_rule",
		method: "GET",
		desc: "角色历史记录"
	},
	chatppt:{
		url: "/addons/chatgpt/web/create_ppt",
		method: "POST",
		desc: "创建ppt"
	},
	getppt:{
		url: "/addons/chatgpt/web/getppt",
		method: "POST",
		desc: "获取ppt作品"
	},
	getpdf:{
		url: "/addons/chatgpt/web/getpdf",
		method: "POST",
		desc: "获取ppt作品"
	},

	pptresult:{
		url: "/addons/chatgpt/web/pptresult",
		method: "POST",
		desc: "查询ppt结果"
	},
	upload_xfyun:{
		url: "/addons/chatgpt/xfmlb/statr",
		method: "POST",
		desc: "文档上传到星火"
	},
	getwss:{
		url: "/addons/chatgpt/xfmlb/getwss",
		method: "POST",
		desc: "获取星火wss"
	},
	getbasewss:{
		url: "/addons/chatgpt/xfmlb/getbasewss",
		method: "POST",
		desc: "获取星火wss"
	},
	save_dialogue:{
		url: "/addons/chatgpt/xfmlb/save_dialogue",
		method: "POST",
		desc: "保存对话记录"
	},
	save_dialogue_base:{
		url: "/addons/chatgpt/xfmlb/save_dialogue_base",
		method: "POST",
		desc: "保存对话记录-知识库"
	},
	zsk_kf_pdf:{
		url: "/addons/chatgpt/xfmlb/zsk_kf_pdf",
		method: "POST",
		desc: "扣费-文档问答"
	},
	zsk_kf:{
		url: "/addons/chatgpt/xfmlb/zsk_kf",
		method: "POST",
		desc: "扣费-知识库"
	},
	
	getbasedata:{
		url: "/addons/chatgpt/xfmlb/getbasedata",
		method: "POST",
		desc: "获取第一个文件的资料"
	},
	edit_name:{
		url: "/addons/chatgpt/xfmlb/edit_name",
		method: "POST",
		desc: "修改pdfname"
	},
	del_pdf:{
		url: "/addons/chatgpt/xfmlb/del_pdf",
		method: "POST",
		desc: "删除pdf"
	},
	del_pdf_base:{
		url: "/addons/chatgpt/xfmlb/del_pdf_base",
		method: "POST",
		desc: "删除pdf"
	},
	sum_up:{
		url: "/addons/chatgpt/xfmlb/sum_up",
		method: "POST",
		desc: "文档总结"
	},
	create_base:{
		url: "/addons/chatgpt/xfmlb/create_base",
		method: "POST",
		desc: "创建知识库"
	},
	get_base:{
		url: "/addons/chatgpt/xfmlb/get_base",
		method: "POST",
		desc: "获取知识库"
	},
	get_base_user:{
		url: "/addons/chatgpt/xfmlb/get_base_user",
		method: "POST",
		desc: "获取知识库"
	},
	del_base:{
		url: "/addons/chatgpt/xfmlb/del_base",
		method: "POST",
		desc: "删除知识库"
	},
	get_base_file:{
		url: "/addons/chatgpt/xfmlb/getbasefile",
		method: "POST",
		desc: "获取知识库文件"
	},
	auth_user:{
		url: "/addons/chatgpt/xfmlb/auth_user",
		method: "POST",
		desc: "授权知识库"
	},
	get_shouquanlist:{
		url: "/addons/chatgpt/xfmlb/get_shouquanlist",
		method: "POST",
		desc: "授权知识库账号列表"
	},
	listdeletes:{
		url: "/addons/chatgpt/xfmlb/listdeletes",
		method: "POST",
		desc: "删除授权"
	}


}

export default role
