<template>

	<!-- Layout Footer -->
	<a-layout-footer class="mb-5" >
		<a-row type="flex">
			<a-col :span="24" :offset="6" justify="center" align="middle">
				<div class="copyrights font-regular text-sm">
					<div class="item" v-for="(item, index) in config.copyrights" :key="'copyright_' + index">
						<img style="height: 12px;" v-if="item.image" :src="item.image" alt="" />
						<a v-if="item.url" :href="item.url" target="_blank" rel="">
							<span>{{ item.name }}</span>
						</a>
						<span v-else>{{ item.name }}</span>
					</div>
				</div>
			</a-col>
		</a-row>
	</a-layout-footer>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {}
		},
		computed: {
			...mapGetters("app", ["config"])
		},
		created() {},
		methods: {}
	}
</script>

<style lang="scss" scoped>
	
	.copyrights {
		display: flex;
		align-items: center;

		.item {
			margin-right: 12px;
			display: flex;
			align-items: center;
            
			&:last-child {
				margin-right: 0;
			}

			img {
				margin-right: 4px;
			}

			span {
				color: #8c8c8c;
			}
		}
	}
</style>
